<template>
  <b-overlay :show="loading">
    <b-row v-if="this.isOwner">
      <b-col cols="4">
        <b-form-group class="mb-1">
          <label class="">Pilih Pengiriman dari Gudang</label>
          <b-form-select id="pilihgudangdari" v-model="pilihgudangdari" :options="dataGudang" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group class="mb-1">
          <label class="">Pilih Pengiriman ke Gudang</label>
          <b-form-select id="pilihgudangke" v-model="pilihgudangke" :options="dataGudangCabang" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group class="mb-1">
          <b-button variant="primary" @click="getpengirimanBarangs()" block>
            <feather-icon icon="SearchIcon" class="mr-50" />
            Cari
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- <form-filter-export @onExport="exportpengirimanBarang"></form-filter-export> -->
        <b-card title="Daftar Pengiriman Barang ke Cabang">
          <b-row v-if="allowCreate()">
            <b-col class="my-1">
              <b-button v-b-modal.modal-tambah variant="primary" v-if="allowCreate() && myGudang"
                @click.prevent="$router.push('/owner/pengiriman/tambah')">
                <!-- @click="add()" -->
                <feather-icon icon="PlusIcon" class="mr-50" />
                Pengiriman Barang ke Cabang
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8">
              <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
                class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Tekan enter untuk cari"
                    @keydown.enter.prevent="getpengirimanBarangs()" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="
                      filter = '';
                    getpengirimanBarangs();
                    ">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table small striped hover responsive :items="pengirimanBarangs" :fields="fieldsasli" :per-page="perPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ row.index + 1 }}
                  </strong>
                </template>

                <!-- <template #cell(status_ket)="data">
              <b-badge :variant="ket_selesai[1][data.value]">
                {{ ket_selesai[0][data.value] }}
              </b-badge>
            </template> -->
                <template #cell(show_detail)="row">
                  <b-form-checkbox v-if="row.item.rincian && row.item.rincian.length > 0" v-model="row.detailsShowing"
                    plain class="vs-checkbox-con" @change="row.toggleDetails">
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                  </b-form-checkbox>
                  <span v-else class="text-danger">
                    <feather-icon icon="SlashIcon" />
                  </span>
                </template>
                <!-- / -->
                <!-- Detail -->
                <template #row-details="{ item, toggleDetails }">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col cols="12">

                        <h5>Daftar Rincian Barang</h5>
                        <b-table-simple>
                          <b-tr>
                            <b-th>No</b-th>
                            <b-th>Nama Barang</b-th>
                            <b-th>Kuantitas</b-th>
                            <b-th>Satuan</b-th>
                            <b-th>Status</b-th>
                          </b-tr>
                          <b-tr :key="i" v-for="(data, i) in item.rincian">
                            <b-td>{{ (i + 1) }}</b-td>
                            <b-td>
                              {{ data.barang ? data.barang.nama : '-' }}
                            </b-td>
                            <b-td>{{ data.qty ? `${formatRupiah(data.qty)}` : '-' }}</b-td>
                            <b-td>{{ data.barang ? `${data.barang.satuan.satuan}` : '-' }}</b-td>
                            <b-td><b-button v-if="isCabang && data.diterima == 0" v-b-tooltip.hover.right="'Terima'"
                                size="sm" rounded class="mr-1" variant="success" @click.prevent="setuju(data)">
                                <feather-icon icon="CheckIcon" />
                                Diterima
                              </b-button>
                              <b-button v-if="isCabang && data.diterima == 0" v-b-tooltip.hover.right="'Tolak'" size="sm"
                                rounded class="mr-1" variant="danger" @click.prevent="tolak(data)">
                                <feather-icon icon="XIcon" />
                                Ditolak
                              </b-button>
                              {{ data && this.isOwner ? data.status.toUpperCase() : "Menunggu" }}
                              <template v-if="data.diterima > 0">
                                <!-- <feather-icon icon="XIcon" /> -->
                                {{ data ? data.status.toUpperCase() : "-" }}
                              </template></b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-col>
                    </b-row>

                    <b-button size="sm" variant="outline-secondary" @click="toggleDetails">
                      Hide
                    </b-button>
                  </b-card>
                </template>

                <template #cell(selesai)="data">
                  <b-badge variant="primary">
                    {{ data.item.status_ket ? data.item.status_ket.toUpperCase() : "-" }}
                  </b-badge>
                </template>
                <template #cell(po)="{ item }">
                  {{ item.po ? item.po.no : "po kosong" }}
                </template>

                <template #cell(tanggal_pengiriman)="{ item }">
                  {{ humanDate(item.tanggal) }}
                </template>

                <template #cell(tanggal_invoice)="{ item }">
                  {{ humanDate(item.tanggal_invoice) }}
                </template>
                <template #cell(actions)="{ item }">
                  <section class="flex item-center">
                    <b-button v-b-tooltip.hover.right="'Detail'" size="sm"
                      @click="$router.push(`/owner/pengiriman/detail/${item.id}`)" class="mr-1" variant="outline-primary">
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <!-- <b-button v-if="(allowUpdate() && item.status == 1) ||
                  (allowUpdate() && !isAdminGudang)
                  " v-b-tooltip.hover.right="'Batalkan Pengiriman'" size="sm" @click="batal(item)" class="mr-1"
                  variant="outline-danger">
                  <feather-icon icon="XCircleIcon" />
                </b-button> -->
                    <b-button v-if="(allowUpdate() && item.status == 0) ||
                      (allowUpdate() && !isAdminGudang)
                      " v-b-tooltip.hover.right="'Ubah'" size="sm"
                      @click="$router.push(`/owner/pengiriman/edit/${item.id}`)" class="mr-1" variant="outline-info">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <!-- v-if="pengirimanBarangs.selesai = 1" -->
                    <b-button v-if="(allowDelete() && item.status == 0) ||
                      (allowUpdate() && !isAdminGudang)
                      " v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(item)" class="mr-1"
                      variant="outline-danger">
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </section>
                </template>
                <template #cell(actionsCabang)="{ item }">
                  <section class="flex item-center">
                    <b-button v-b-tooltip.hover.right="'Detail'" size="sm"
                      @click="$router.push(`/owner/pengiriman/detail/${item.id}`)" class="mr-1" variant="outline-primary">
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </section>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import {
  BOverlay,
  BModal,
  BTable,
  VBTooltip,
  BTooltip,
  BAvatar,
  BCard,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormTextarea,
  BTableSimple,
  BTh,
  BTr,
  BTd,
} from "bootstrap-vue";
import mixin from './mixin'
export default {
  mixins: [mixin],
  components: {
    BForm,
    FormFilterExport,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BFormCheckbox,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BFormSelect,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BTableSimple,
    BTh,
    BTr,
    BTd,
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data: () => ({
    pilihgudangdari: null,
    pilihgudangke: null,
    required,
    password,
    email,
    confirmed,
    params: {
      from_gudang_id: null,
    },
    loading: false,
    id: null,
    form: {
      // form tab content 1
      // gudang_id: 1, // sementara
      from_gudang_id: null,
      to_gudang_id: null,
      no_invoice: null,
      jenis: 0,
      tanggal_invoice: null,
      tanggal_pengiriman: null,
      keterangan: null,
    },
    options: [
      { text: 'Otomatis', value: '1' },
      { text: 'Manual', value: '0' },
    ],
    dataGudang: [],
    dataGudangCabang: [],
    otherGudangs: [],
    perPage: 5,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    pengirimanBarangs: [],
    directive: {
      "b-tooltip": VBTooltip,
    },
    ket_selesai: [
      {
        0: "Belum ditempatkan",
        1: "Sudah",
      },
      {
        0: "light-danger",
        1: "light-success",
      },
    ],
    fieldsGudang: [
      { key: "show_detail", label: "Detail" },
      {
        key: "no",
        label: "No",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal_pengiriman",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "to.nama_gudang", label: "Gudang Tujuan", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
    fieldsOwner: [
      {
        key: "no",
        label: "No",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal_pengiriman",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "from.nama_gudang", label: "Gudang Asal", sortable: true },
      { key: "to.nama_gudang", label: "Gudang Tujuan", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "show_detail", label: "Detail" },
    ],
    showModal: false,
    fieldsasli: null,
    fields: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "gudang.nama_gudang",
        label: "Gudang",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    "form.jenis"(j) {
      if (j == 1) {
        this.form.no_invoice = this.getNewNoInvoice();
      } else {
        this.form.no_invoice = null;
      }
    },
    // pilihgudangdari(value){
    //   if (value) {
    //     this.getpengirimanBarangs();
    //   }
    // },
    perPage(value) {
      if (value) {
        this.getpengirimanBarangs();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getpengirimanBarangs();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getpengirimanBarangs();
        // this.items
      }
    },
  },
  methods: {
    coba() {
      if (this.isAdminGudang) {
        return this.fieldsasli = this.fieldsGudang
      } else if (this.isOwner) {
        return this.fieldsasli = this.fieldsOwner
      } else if (this.isCabang) {
        return this.fieldsasli = this.fieldsCabang
      } else {
        this.fieldsasli = this.fields
      }
    },
    batal(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dibatalkan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/batal", {
              pengiriman_id: item.id,
              metode: 'transaksi',
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dibatalkan",
              });
              this.getpengirimanBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async exportpengirimanBarang({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayWarning({
          text: 'Harap isi tanggal awal dan akhir!'
        })
        return false
      }

      const params = {
        tgl_awal,
        tgl_akhir,
        type_export
      }

      try {
        this.loading = true
        const response = await this.doExport(params)
        this.loading = false
        await window.open(response)
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split('/')
          const filename = arrFile[arrFile.length - 1]

          await this.clearExportedFile(filename)
        }, 1000)
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    submit() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          if (this.id) {
            this.form.id = this.id;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          // const payload = this.form;
          const payload = {
            from_gudang_id: this.form.from_gudang_id,
            to_gudang_id: this.form.to_gudang_id,
            karyawan_id: this.user.id,
            items: [
              penyimpanan
            ],
            // keterangan: null,
          }
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("pengiriman/save", payload);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                text: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                text: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/pengiriman/add/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    getDataGudang() {
      const payload = {
        sebagai: "pusat",
        jenis : 1,
      };
      this.$store
        .dispatch("gudang/getData", payload)
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];
          if (this.isAdminGudang) {
            ter.map((gudang) => {
              const itsMyGudang = gudang.id == this.myGudang.id;
              if (itsMyGudang) {
                this.form.from_gudang_id = this.myGudang.id;
                gudangs.push({
                  value: gudang.id,
                  text: gudang.nama_gudang,
                });
              }
            });
          } else {
            ter.map((item) => {
              gudangs.push({
                value: item.id,
                text: item.nama_gudang,
              });
            });
          }
          this.dataGudang = gudangs;
          // ter.map((gudang) => {
          //   if (gudang.id != this.myGudang.id) {
          //     this.dataGudang.push({
          //       value: gudang.id,
          //       text: gudang.nama_gudang,
          //     });
          //   }
          // });
        });
    },
    getDataGudangCabang() {
      const payload = {
        sebagai: "cabang",
        jenis : 1,
      };
      this.$store
        .dispatch("gudang/getData", payload)
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];
          if (this.isAdminGudang) {
            ter.map((gudang) => {
              const itsMyGudang = gudang.id == this.myGudang.id;
              if (itsMyGudang) {
                this.form.from_gudang_id = this.myGudang.id;
                gudangs.push({
                  value: gudang.id,
                  text: gudang.nama_gudang,
                });
              }
            });
          } else {
            ter.map((item) => {
              gudangs.push({
                value: item.id,
                text: item.nama_gudang,
              });
            });
          }
          this.dataGudangCabang = gudangs;
          // ter.map((gudang) => {
          //   if (gudang.id != this.myGudang.id) {
          //     this.dataGudang.push({
          //       value: gudang.id,
          //       text: gudang.nama_gudang,
          //     });
          //   }
          // });
        });
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "pengiriman/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      this.form.tanggal_pengiriman = this.getCurrentDate()
      this.form.tanggal_invoice = this.getCurrentDate()
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/deletepengiriman", {
              pengiriman_id: item.id,
              metode: "main"
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.getpengirimanBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getpengirimanBarangs() {
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        // filter_by: "",
        // kode: this.caribarang ? this.caribarang : "",
        // from_gudang_id: this.myGudang.id,
      };
      if (this.isOwner) {
        payload.from_gudang_id = this.pilihgudangdari ? this.pilihgudangdari : ""
        payload.to_gudang_id = this.pilihgudangke ? this.pilihgudangke : ""
      }
      if (this.isAdminGudang || this.isCabang) {
        payload.from_gudang_id = this.myGudang ? this.myGudang.id : ""
      }
      // let payload = this.isAdminGudang
      //   ? { from_gudang_id: this.myGudang.id, order: "desc" }
      //   : { order: "desc" };
      const pengirimanBarangs = await this.$store.dispatch(
        "pengiriman/getData",
        payload
      );
      let items = JSON.parse(JSON.stringify(this.$store.state.pengiriman.datas));
      let items_total = this.$store.state.pengiriman.totals;
      this.pengirimanBarangs = items;
      // this.items = items;
      this.totalRows = items_total;
      this.loading = false
      // this.pengirimanBarangs = pengirimanBarangs.data;
      // this.totalRows = this.pengirimanBarangs.length;
      // this.totalRows = pengirimanBarangs.total;
    },
  },
  async created() {
    if (this.isOwner) {
      await this.getDataGudang();
    await this.getDataGudangCabang();
      await this.coba()
    } else {
      await this.coba()
      await this.getpengirimanBarangs();
      await this.getDataGudang();
    await this.getDataGudangCabang();
    }
  },
};
</script>
